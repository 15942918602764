import React from 'react';
import { Route } from 'react-router-dom';

// components
import Header from './components/Header';
import Footer from './components/Footer';

// pages
import Default from './pages/Homepage';
import RankingList from './pages/RankingList';
import ToyCompanies from './pages/ToyCompanies';
import ArticleList from './pages/ArticleList';
import Article from './pages/Article';

export default function App() {
  return (
    <>
      <Header />

        <Route exact path="/" component={Default} />
        <Route path="/article" component={Article} />
        <Route exact path="/articles" component={ArticleList} />
        <Route exact path="/toyrankings" component={RankingList} />
        <Route exact path="/tracker" component={ToyCompanies} />
        <Route path='/contest' component={() => {
          window.location.href = 'https://playr.gg/giveaway/MQmyha0';
          return null;
        }}/>

      <Footer />
    </>
  );
}