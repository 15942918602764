import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import axios from 'axios';

export class AuthorDets extends Component {
  state = {
    author: [],
    isLoaded: false
  }

  componentDidMount () {
    // console.dir(this.props.data);
    let self = this;

    const params = {
      filter: { _id: this.props.data }
    }

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;
    // const getAuthor  = axios.get(api+'/authors?token='+token+'&filter[_id]='+this.props.data);
    const getAuthor  = axios.get(`${api}/author?api_key=${token}`, { params });

    axios.all([getAuthor]).then(res => {
      // console.dir(res);
      const authorDet = res[0].data[0];

      self.setState({
        author: authorDet,
        isLoaded: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { isLoaded, author } = this.state;
    // console.dir(author);

    return (
      <>
      {isLoaded && (
        <>
          <section className="authorBlock">
            <h2><i className="fa fa-user-circle" /> Author</h2>

            {(author.photo) && (
              <div className="authorPhoto">
                <img src={ 'https://admin.toyrewindpodcast.com/storage/uploads' + author.photo.path } alt={ author.name } />
              </div>
            )}

            <div className="authorDets">
              <p>{ author.firstname } { author.lastname }</p>
              <p><a href={ 'https://www.twitter.com/' + author.twitter} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i> { author.twitter }</a></p>
              <p><a href={ 'https://www.instagram.com/' + author.instagram} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter"></i> { author.instagram }</a></p>
            </div>
          </section>
        </>
      )}
      </>
    )
  }
}

export default AuthorDets;