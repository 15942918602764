import React from 'react';
// import { Link } from 'react-router-dom';

function Hero() {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Welcome to the Toy Rewind Podcast</h1>

              <ul>
                <li>
                  <a target="_blank" href="https://open.spotify.com/show/0TngUMFUotesvNleDcX6vY?si=t7JHBzSEQ5iAKdrEuCT-nw" rel="noopener noreferrer" title="Spotify">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/spotify.png'} alt="Listen on Spotify" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://podcasts.apple.com/us/podcast/toy-rewind-podcast/id1525088831" rel="noopener noreferrer" title="Apple Podcasts">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/apple.png'} alt="Listen on Apple Podcasts" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnRveXJld2luZHBvZGNhc3QuY29tLw" rel="noopener noreferrer" title="Google">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/googlepodcasts.png'} alt="Listen on Google Podcasts" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.pandora.com/podcast/all-episodes/toy-rewind-podcast/PC:50465" rel="noopener noreferrer" title="Pandora">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/pandora.png'} alt="Listen on Pandora" />
                  </a>
                </li>
                {/* <li>
                  <a target="_blank" href="https://google.com" rel="noopener noreferrer" title="GooglePlay">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/googleplay.png'} alt="Listen on Google Play" />
                  </a>
                </li> */}
                {/* <li>
                  <a target="_blank" href="https://soundcloud.com/toyrewind-podcast" rel="noopener noreferrer" title="SoundCloud">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/soundcloud.png'} alt="Listen on SoundCloud" />
                  </a>
                </li> */}
                {/* <li>
                  <a target="_blank" href="https://toyrewindpodcast.podbean.com/" rel="noopener noreferrer" title="PodBean">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/podbean.png'} alt="Listen on PodBean" />
                  </a>
                </li> */}
                <li>
                  <a target="_blank" href="https://music.amazon.com/podcasts/9d8dd456-6254-4449-8068-32180c15eb3c/Toy-Rewind-Podcast" rel="noopener noreferrer" title="Amazon Music">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/amazonmusic.png'} alt="Listen on Amaon Music" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.podchaser.com/podcasts/toy-rewind-podcast-1357578" rel="noopener noreferrer" title="PodChaser">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/podchaser.jpg'} alt="Check us out on PodChaser" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.iheart.com/podcast/269-toy-rewind-podcast-69196659/" rel="noopener noreferrer" title="iHeartRadio">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/iheart.png'} alt="Listen on iHeartRadio" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.stitcher.com/podcast/toy-rewind-podcast" rel="noopener noreferrer" title="Sticher">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/sticher.png'} alt="Listen on Sticher" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://tunein.com/podcasts/Media--Entertainment-Podcasts/Toy-Rewind-Podcast-p1351497/" rel="noopener noreferrer" title="TuneIn">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/tunein.png'} alt="Listen on TuneIn" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://pca.st/ykutowx4" rel="noopener noreferrer" title="Pocket Casts">
                    <img src={process.env.PUBLIC_URL + '/global/images/icons/pocketcasts.png'} alt="Listen on Pocket Casts" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;