import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Header extends Component  {
  componentDidMount() {
    window.addEventListener('scroll', this.stickyHeader);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickyHeader);
  }

  stickyHeader() {
    let header = document.querySelector('header');
    (window.pageYOffset > header.clientHeight)? header.classList.add('sticky') : header.classList.remove('sticky');
  }

  render() {
    return (
      <>
        <header className="header">
          <div className="container">
            <div className="row">
              <div className="col-4 logo">
                <embed src={process.env.PUBLIC_URL + '/global/images/toyrewind_logo.png'} />
              </div>

              <div className="col-8 navi">
                <ul>
                  <li><a href="/?page=1">Episodes</a></li>
                  <li><a href="https://www.podcastbingo.com/toyrewind" target="_blank" rel="noopener noreferrer">Bingo</a></li>
                  <li><a href="https://www.teepublic.com/user/toy-rewind-podcast" target="_blank" rel="noopener noreferrer">Merch</a></li>
                  <li><Link to="/tracker">Tracker</Link></li>
                  <li><Link to="/toyrankings">Rankings</Link></li>
                  <li><Link to="/articles">Articles</Link></li>
                  <li><a href="https://www.crossthestreamsmedia.com" target="_blank" rel="noopener noreferrer">CTS Media</a></li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;