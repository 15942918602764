import React, { Component } from 'react';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import Hero from '../components/heroes/Hero';
import FeaturedPodcast from '../components/blocks/FeaturedPodcastBlock';

export class Page extends Component {
  state = {
    podcasts: [],
    isLoaded: false,
    featuredLodaed: false,
    michael: [{ toy_line: '&nbsp;', _id: '00' }],
    john: [{ toy_line: '&nbsp;', _id: '00' }],
    andrew: [{ toy_line: '&nbsp;', _id: '00' }]
  }

  _pageTitle = 'Toy Line Rankings | Toy Rewind Podcast';
  _pageDescription = 'The rankings given to the toy lines by each brother after the reviews.';
  _pageKeywords = 'podcast,toy rewind podcast,toyrewind,retro toy podcast,toy rankings,brackets';

  changeRound = (e, data) => {
    e.preventDefault();
    this.setState({ selectedRound: data });
  }

  componentDidMount() {
    console.log('Toy Rankings Page');
    window.scrollTo(0, 0);

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;

    const parsed = window.location.search;
    const round = (window.location.search.indexOf('round') > -1) ? parsed.split("round=")[1] : 5;

    const params = {
      limit: 2,
      sort: { pubDate: -1 }
    }

    const rankingParams = {
      filter: { round: round },
      sort: { _o: 1 }
    }

    const podcasts = axios.get(`${api}/episode?api_key=${token}`, { params });
    const rankingsM = axios.get(`${api}/rankingsmichael?api_key=${token}`, { params : rankingParams });
    const rankingsJ = axios.get(`${api}/rankingsjohn?api_key=${token}`, { params : rankingParams });
    const rankingsA = axios.get(`${api}/rankingsandrew?api_key=${token}`, { params : rankingParams });

    let self = this;
    axios.all([podcasts, rankingsM, rankingsJ, rankingsA])
    .then(function (response) {
      const publishedCasts = [];
      const now = new Date();

      response[0].data.forEach(element => {
        const pd = new Date(element.pubDate);

        if (pd.getTime() < now.getTime()) {
          publishedCasts.push(element);
        }
      });

      self.state.michael = [];
      response[1].data.forEach(element => {
        const pd = new Date(element.pubDate);
        if (pd.getTime() < now.getTime()) {
          self.state.michael.push(element);
        }
      });

      self.state.john = [];
      response[2].data.forEach(element => {
        const pd = new Date(element.pubDate);
        if (pd.getTime() < now.getTime()) {
          self.state.john.push(element);
        }
      });

      self.state.andrew = [];
      response[3].data.forEach(element => {
        const pd = new Date(element.pubDate);
        if (pd.getTime() < now.getTime()) {
          self.state.andrew.push(element);
        }
      });

      self.setState({
        podcasts: publishedCasts,
        isLoaded: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    let { podcasts, michael, john, andrew } = this.state;

    const meta = {
      title: this._pageTitle,
      description: this._pageDescription,
      canonical: 'https://toyrewindpodcast.com/toyrankings/',
      meta: {
        name: {
          keywords: this._pageKeywords,
        },
        property: {
          'og:title': this._pageTitle,
          'og:site_name': 'Toy Rewind Podcast',
          'og:url': 'https://toyrewindpodcast.com/toyrankings/',
          'og:description': this._pageDescription,
          'og:type': 'website',
          'og:image': 'https://toyrewindpodcast.com/global/images/trp-ogimage.png',
        }
      }
    };

    const featuredPodcast = podcasts.slice(0,1).map((feat) => {
      return <FeaturedPodcast key={ feat._id } podcast={ feat } published={ feat.published } />
    });

    return (
      <>
        <DocumentMeta {...meta} />
        <Hero title="Our Toy Rankings" extraClass="shorty" />
        { featuredPodcast }

        <section className="content">
          <section className="roundPicker">
            <a href={'/toyrankings?round=1'}>Round 1</a>
            <a href={'/toyrankings?round=2'}>Round 2</a>
            <a href={'/toyrankings?round=3'}>Round 3</a>
            <a href={'/toyrankings?round=4'}>Round 4</a>
            <a href={'/toyrankings?round=5'}>Round 5</a>
          </section>

          <div className="container">
            <div className="row">
              <div className="col-4 rankinglist">
                <h3>Michael</h3>
                <img src={process.env.PUBLIC_URL + '/global/images/photos/michael_headshot.jpg'} alt="Michael" className="headshot"/>

                <ol>
                  {michael.map((toy, index) =>
                    <li key={ toy._id } dangerouslySetInnerHTML={{ __html: toy.toyLine }} data-number={ index + 1 } />
                  )}
                </ol>
              </div>

              <div className="col-4 rankinglist">
                <h3>John</h3>
                <img src={process.env.PUBLIC_URL + '/global/images/photos/john_headshot.jpg'} alt="John" className="headshot"/>

                <ol>
                  {john.map((toy, index) =>
                    <li key={ toy._id } dangerouslySetInnerHTML={{ __html: toy.toyLine }} data-number={ index + 1 } />
                  )}
                </ol>
              </div>

              <div className="col-4 rankinglist">
                <h3>Andrew</h3>
                <img src={process.env.PUBLIC_URL + '/global/images/photos/andrew_headshot.jpg'} alt="Andrew" className="headshot"/>

                <ol>
                  {andrew.map((toy, index) =>
                    <li key={ toy._id } dangerouslySetInnerHTML={{ __html: toy.toyLine }} data-number={ index + 1 } />
                  )}
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Page;