import React, { Component } from 'react';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import { format } from 'date-fns';

import ArticlePodcast from '../components/blocks/ArticlePodcast';
import PhotoGallery from '../components/blocks/PhotoGallery';
import AuthorDets from '../components/blocks/AuthorDets';

export class Page extends Component {
  state = {
    article: [],
    isLoaded: false
  }

  _pageKeywords = 'toy reviews,classic toys,retro toys,podcast,toy rewind podcast,toyrewind,retro toy podcast'

  componentDidMount() {
    console.log('Article Page');
    window.scrollTo(0, 0);

    let path = window.location.pathname.split('/')[2];
    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;

    const params = {
      limit: 12,
      // skip: skipnum,
      sort: { pubDate: -1 },
      filter: { permalink: path }
    }

    // category search (filter[categories]=Teenage%20Mutant%20Ninja%20Turtles);
    // const articles  = axios.get(api+'/article?token='+token+'&filter[pub_date][$lte]='+pubdate+'&sort[pub_date]=-1&filter[published]=true');
    // const getArticles  = axios.get(api+'/article?token='+token+'&filter[permalink]='+path);
    const getArticles  = axios.get(`${api}/article?api_key=${token}`, { params });

    let self = this;
    axios.all([getArticles]).then(res => {
      const articleContent = res[0].data[0];

      this._pageTitle = `Toy Rewind Podcast Article - ${articleContent.title}`;
      articleContent.keywords.map((index) => this._pageKeywords += `,${index}`);
      this._pageDescription = `${articleContent.body.replace(/<p>/g, '').split('.')[0]}.`;

      const authorParams = {
        filter: { _id: res[0].data[0].author._id }
      }

      const getAuthor  = axios.get(`${api}/author?api_key=${token}`, { authorParams });

      axios.all([getAuthor]).then(res => {
        articleContent.author.display = `${res[0].data[0].firstname} ${res[0].data[0].lastname}`;

        self.setState({
          isLoaded: true
        });
      })
      .catch(function (error) {
        console.log(error);
      });

      self.setState({
        article: articleContent
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    let { article, isLoaded } = this.state;
    console.dir(article);

    const meta = {
      title: this._pageTitle,
      description: this._pageDescription,
      canonical: 'https://toyrewindpodcast.com/article/'+article.permalink,
      meta: {
        name: {
          keywords: this._pageKeywords,
        },
        property: {
          'og:title': this._pageTitle,
          'og:site_name': 'Toy Rewind Podcast',
          'og:url': 'https://toyrewindpodcast.com/article/'+article.permalink,
          'og:description': this._pageDescription,
          'og:type': 'website',
          'og:image': 'https://toyrewindpodcast.com/global/images/trp-ogimage.png',
        }
      }
    };

    return (
      <>
        {isLoaded && (
          <>
            <DocumentMeta {...meta} />
            <section className="content noHero">
              <div className="container">
                <div className="row fullArticle">

                  <div className="col-12">
                    <h1>{ article.title }</h1>
                    {(article.author) && (
                      <div className="timestamp">{ article.author.display } | { format(new Date(article.pubDate), 'EEEE, LLLL dd, yyyy') }</div>
                    )}
                  </div>

                  <div className="col-8">
                    {(article.featuredImage) && (
                      <div className="photoGallery" style={{ backgroundImage: 'url(https://admin.toyrewindpodcast.com/storage/uploads' + article.featuredImage.path + ')' }}></div>
                    )}
                    <div className="copy" dangerouslySetInnerHTML={{ __html: article.body }} />

                    {(article.photoGallery.length > 0 ) && (
                      <PhotoGallery media={ article.photoGallery } />
                    )}
                  </div>

                  <div className="col-4 sidebar">
                    {(article.toyDetails.releaseYear) && (
                      <section className="statsBlock">
                        <h2><i className="fa-solid fa-circle-info"></i> Toy Facts</h2>
                        <div className="statsCont">
                          {(article.toyDetails.releaseYear) && (
                            <div className="stat">
                              <p>Release Year:</p>
                              <p>{ article.toyDetails.releaseYear }</p>
                            </div>
                          )}

                          {(article.toyDetails.ogPrice) && (
                            <div className="stat">
                              <p>Original Price:</p>
                              <p>${ article.toyDetails.ogPrice }</p>
                            </div>
                          )}

                          {(article.toyDetails.toyCompany) && (
                            <div className="stat">
                              <p>Manufacturer:</p>
                              <p>{ article.toyDetails.toyCompany }</p>
                            </div>
                          )}
                        </div>
                      </section>
                    )}

                    {(article.episodeLink) && (
                      <ArticlePodcast data={ article.episodeLink._id } />
                    )}

                    {(article.author) && (
                      <AuthorDets data={ article.author._id } />
                    )}

                    {(article.keywords.length > 0 ) && (
                      <section className="tagCloud">
                        {article.keywords.map((cat, index) =>
                          <p key={ index }>{ cat }</p>
                        )}
                      </section>
                    )}
                  </div>

                </div>
              </div>
            </section>
          </>
        )}
      </>
    );
  }
}

export default Page;