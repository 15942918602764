import React, { Component } from 'react'
// import { Link } from 'react-router-dom';

export class Hero extends Component {
  componentDidMount () { }

  render() {
    return (
      <>
        <section className="hero default">
          <div className="container">
            <div className="row">
              <div className={ 'col-12 ' + this.props.extraClass }>
                <h1>{ this.props.title }</h1>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Hero;