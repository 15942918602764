import React, { Component } from 'react';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import Hero from '../components/heroes/Hero';
import FeaturedPodcast from '../components/blocks/FeaturedPodcastBlock';

export class Page extends Component {
  state = {
    podcasts: [],
    isLoaded: false,
    featuredLodaed: false,
    companyList: [],
  }

  _pageTitle = 'Toy Company Tracker | Toy Rewind Podcast';
  _pageDescription = 'The toy companies we have talked about over the course of the podcast.';
  _pageKeywords = 'podcast,toy rewind podcast,toyrewind,retro toy podcast,toy rankings,brackets';

  changeRound = (e, data) => {
    e.preventDefault();
    this.setState({ selectedRound: data });
  }

  componentDidMount() {
    console.log('Toy Companies');
    window.scrollTo(0, 0);

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;

    // const today = new Date();
    // const pubdate = today.toISOString().split('T')[0] + 'T00:00:00-07:00';

    // const podcasts  = axios.get(api+'/episodes?token='+token+'&filter[pub_date][$lte]='+pubdate+'&sort[pub_date]=-1&filter[published]=true');

    const params = {
      sort: {pubDate: -1}
    }

    const podcasts = axios.get(`${api}/episode?api_key=${token}`, { params });

    let self = this;
    axios.all([podcasts])
    .then(function (response) {
      const publishedCasts = [];
      const companies = [];
      const now = new Date();

      response[0].data.forEach(element => {
        const pd = new Date(element.pubDate);

        if (pd.getTime() < now.getTime()) {
          publishedCasts.push(element);
        }

        if (element.toyCompany && element.toyCompany.length >= 1) {
          element.toyCompany.forEach(element => {
            companies.push(element);
          });
        }
      });

      const companyBreakdown = companies.reduce(function (acc, curr) {
        // eslint-disable-next-line no-sequences
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
      }, {});

      const breakdown = Object.entries(companyBreakdown);

      self.setState({
        podcasts: publishedCasts,
        companyList: breakdown.sort((a, b) => b[1] - a[1]),
        isLoaded: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    let { podcasts, companyList } = this.state;

    const meta = {
      title: this._pageTitle,
      description: this._pageDescription,
      canonical: 'https://toyrewindpodcast.com/tracker/',
      meta: {
        name: {
          keywords: this._pageKeywords,
        },
        property: {
          'og:title': this._pageTitle,
          'og:site_name': 'Toy Rewind Podcast',
          'og:url': 'https://toyrewindpodcast.com/tracker/',
          'og:description': this._pageDescription,
          'og:type': 'website',
          'og:image': 'https://toyrewindpodcast.com/global/images/trp-ogimage.png',
        }
      }
    };

    const featuredPodcast = podcasts.slice(0,1).map((feat) => {
      return <FeaturedPodcast key={ feat._id } podcast={ feat } published={ feat.published } />
    });

    return (
      <>
        <DocumentMeta {...meta} />
        <Hero title="Toy Company Traker" extraClass="shorty" />
        { featuredPodcast }

        <section className="content">
          <div className="container">
            <div className="row flex_start">

              {companyList.map((company) =>
                <div className="col-4 company" key={ company[0] }>
                  <h2>{ company[0].replace('&amp;', '&') } <span>{ company[1] }</span></h2>
                </div>
              )}

            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Page;