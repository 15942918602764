import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import Hero from '../components/heroes/HomepageHero';
import FeaturedPodcast from '../components/blocks/FeaturedPodcastBlock';
import Podcasts from '../components/blocks/PodcastBlock';
import Pagination from '../components/blocks/Pagination';

export class Page extends Component {
  state = {
    podcasts: [],
    fp: [],
    isLoaded: false,
    featuredLodaed: false
  }

  _pageTitle = 'Toy Rewind Podcast';
  _pageDescription = 'A trio of brothers that grew up in the 80s and 90s decided to get together and talk about some of the old toys they had growing up.';
  _pageKeywords = 'podcast,toy rewind podcast,toyrewind,retro toy podcast';

  componentDidMount() {
    window.scrollTo(0, 0);

    const parsed = window.location.search;
    const page = (window.location.search.indexOf('page') > -1) ? parsed : '?page=1';

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;

    const skipnum = (page.split("page=")[1] - 1) * 12;
    const today = new Date();
    const pubdate = today.toISOString().split('T')[0] + 'T00:00:00-07:00';

    const params = {
      limit: 12,
      skip: skipnum,
      sort: { pubDate: -1 },
      filter: { pubDate: {$lt: pubdate} }
    }

    const featuredParams = {
      limit: 2,
      sort: { pubDate: -1 },
      filter: { pubDate: {$lt: pubdate} }
    }

    const getPodcasts = axios.get(`${api}/episode?api_key=${token}`, { params });
    const getFeatured = axios.get(`${api}/episode?api_key=${token}`, { params : featuredParams });

    axios.all([getPodcasts, getFeatured]).then(res => {
      const publishedCasts = [];
      // console.dir(res[1]);

      res[0].data.data.forEach(element => {
        publishedCasts.push(element);
      });

      this.setState({
        fp: res[1].data,
        podcasts: publishedCasts,
        isLoaded: true
      })
    })
    .catch(err => console.log(err));
  }

  render() {
    const { podcasts, fp } = this.state;

    const meta = {
      title: this._pageTitle,
      description: this._pageDescription,
      canonical: 'https://toyrewindpodcast.com/',
      meta: {
        name: {
          keywords: this._pageKeywords,
        },
        property: {
          'og:title': this._pageTitle,
          'og:site_name': 'Toy Rewind Podcast',
          'og:url': 'https://toyrewindpodcast.com/',
          'og:description': this._pageDescription,
          'og:type': 'website',
          'og:image': 'https://toyrewindpodcast.com/global/images/trp-ogimage.png',
        }
      }
    };

    const featuredPodcast = fp.slice(0,1).map((feat) => {
      return <FeaturedPodcast key={ feat._id } podcast={ feat } published={ feat.published } />
    });

    const episodeList = podcasts.map((episode) => {
      return <Podcasts key={ episode._id } podcast={ episode } published={ episode.published } />
    });

    return (
      <>
        <DocumentMeta {...meta} />
        <Hero />
        { featuredPodcast }

        <section className="content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Latest Episodes</h2>
              </div>

              { episodeList }

              <Pagination />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Page;