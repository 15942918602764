import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

export class ArticlePodcast extends Component {
  state = {
    episode: [],
    isLoaded: false
  }

  componentDidMount () {
    // console.dir(this.props.data);
    let self = this;

    const params = {
      filter: { _id: this.props.data }
    }

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;
    // const getEpisode  = axios.get(api+'/podcast?token='+token+'&filter[_id]='+this.props.data);
    const getEpisode  = axios.get(`${api}/episode?api_key=${token}`, { params });

    axios.all([getEpisode]).then(res => {
      // console.dir(res);
      const episodeContent = res[0].data[0];

      self.setState({
        episode: episodeContent,
        isLoaded: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { isLoaded, episode } = this.state;
    // console.dir(episode);

    return (
      <>
      {isLoaded && (
        <>
          <section className="podcastBlock">
            <h2><i className="fa-solid fa-headphones" /> Podcast</h2>
            <div className="episodeCont">
              <div className="thumb">
                <img src={ 'https://admin.toyrewindpodcast.com/storage/uploads' + episode.feedThumbnail.path } alt={ episode.title } />
              </div>

              <div className="title">
                <h3>{ episode.title.split(':')[0] +':' } <br/> { episode.title.split(':')[1] }</h3>
                <p className="timestamp">{ format(new Date(episode.pubDate), 'LLLL dd, yyyy') } | { episode.episodeType }</p>
                {/* <p>{ episode.description.substring(0,80) + '...' }</p> */}
              </div>
            </div>

          </section>
        </>
      )}
      </>
    )
  }
}

export default ArticlePodcast;