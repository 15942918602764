import React, { Component } from 'react'
// import PropTypes from 'prop-types';
import axios from 'axios';

export class Pagination extends Component {
  state = {
    posts: 0,
    isLoaded: false
  }

  componentDidMount () {
    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;

    const params = {
      sort: {pubDate: -1}
    }

    axios.get(`${api}/episode?api_key=${token}`, { params }).then(res => {
      // console.dir(res);
      this.setState({
        posts: res.data.length,
        isLoaded: true
      })
    })
    .catch(err => console.log(err));
  }

  render() {
    // console.log(this.state.isLoaded);
    const pages = [];
    const numpages = Math.ceil(this.state.posts / 12);
    // const cont = document.querySelector('.pagination');
    let i = 1;

    while ( i <= numpages ) {
      // let btn = document.createElement('a');
      // btn.setAttribute('href', '/?page='+i);
      // btn.innerHTML = i;

      // cont.appendChild(btn);
      pages.push(i);
      i++;
    }

    return (
      <div className="pagination">
        {pages.map((page, key) =>
          <a key={ key } href={'/?page='+page} dangerouslySetInnerHTML={{ __html: page }} />
        )}
      </div>
    )
  }
}

export default Pagination