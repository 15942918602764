import React, { Component } from 'react';
import axios from 'axios';
import { compareAsc, format } from 'date-fns';
import DocumentMeta from 'react-document-meta';

import Hero from '../components/heroes/Hero';

export class Page extends Component {
  state = {
    articles: [],
    isLoaded: false
  }

  _pageTitle = 'Toy Rewiews | Toy Rewind Podcast';
  _pageDescription = 'Toy reviews from classic toy lines from the 70\'s to the present from the Brothers Newland.';
  _pageKeywords = 'toy reviews,classic toys,retro toys,podcast,toy rewind podcast,toyrewind,retro toy podcast';

  componentDidMount() {
    console.log('Article List');
    window.scrollTo(0, 0);

    const api = process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;
    const today = new Date();
    // const pubdate = today.toISOString().split('T')[0] + 'T00:00:00-05:00';
    const pubdate = today.toISOString().split('T')[0] + 'T00:00:00-07:00';

    const params = {
      limit: 12,
      // skip: skipnum,
      sort: { pubDate: -1 },
      filter: { pubDate: {$lt: pubdate} }
    }

    // category search (filter[categories]=Teenage%20Mutant%20Ninja%20Turtles);
    // const articles  = axios.get(api+'/article?token='+token+'&filter[pubDate][$lte]='+pubdate+'&sort[pubDate]=-1&filter[published]=true');
    const getArticles  = axios.get(`${api}/article?api_key=${token}`, { params });

    let self = this;
    axios.all([getArticles]).then(res => {
      console.dir(res);
      const publishedArticles = [];

      res[0].data.forEach(element => {
        if (compareAsc(new Date(), new Date(element.pubDate)) === 1) {
          publishedArticles.push(element);
        }
      });

      self.setState({
        articles: publishedArticles,
        isLoaded: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  gotoArticle = (e) => {
    let link = e.target.dataset.link;
    // console.dir(link);
    this.props.history.push('/article/'+link);
  }

  render() {
    let { articles, isLoaded } = this.state;

    const meta = {
      title: this._pageTitle,
      description: this._pageDescription,
      canonical: 'https://toyrewindpodcast.com/articles/',
      meta: {
        name: {
          keywords: this._pageKeywords,
        },
        property: {
          'og:title': this._pageTitle,
          'og:site_name': 'Toy Rewind Podcast',
          'og:url': 'https://toyrewindpodcast.com/articles/',
          'og:description': this._pageDescription,
          'og:type': 'website',
          'og:image': 'https://toyrewindpodcast.com/global/images/trp-ogimage.png',
        }
      }
    };

    return (
      <>
        <DocumentMeta {...meta} />
        <Hero title="Toy Rewind Articles and Reviews" />

        {isLoaded && (
          <section className="content">
            <div className="container">
              <div className="row articleList">
                {articles.map((article, index) =>
                  <div className="col-4 article" key={ article._id } onClick={ this.gotoArticle } data-link={ article.permalink }>
                    {article.featuredImage && (
                      <div className="featuredImage" style={{ backgroundImage: 'url(https://admin.toyrewindpodcast.com/storage/uploads' + article.featuredImage.path + ')' }}></div>
                    )}

                    <div className="body">
                      <p className="timestamp">{ format(new Date(article.pubDate), 'EEEE, LLLL dd, yyyy') }</p>
                      <h3>{ article.title }</h3>
                      <p className="desc">{ article.short }</p>
                    </div>
                    {article.toyDetails.releaseYear && (
                      <div className="ini" title={ article.toyDetails.toyCompany + ' | ' + article.toyDetails.releaseYear }>{ article.toyDetails.releaseYear }</div>
                    )}

                    <div className="icons">
                      {/* <div className=""><i className="fas fa-comment-dots"></i> 250</div> */}
                      {/* <div className=""><i className="fas fa-share-alt"></i></div> */}
                      {/* <div className=""><i className="fas fa-share-alt"></i></div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default Page;