import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import axios from 'axios';

export class PodcastBlock extends Component {
  static propTypes = {
    podcast: PropTypes.object.isRequired
  }

  componentDidMount () {
    // console.dir(this.props.podcast);
    // const { featured_media, author } = this.props.post;
    // const getImageUrl = axios.get(`https://app.marca-global.com/wp-json/wp/v2/media/${featured_media}`);

    // Promise.all([getImageUrl, getAuthor]).then(res => {
    // console.dir(res);
    //   this.setState({
    //     imgUrl: res[0].data.media_details.sizes.full.source_url,
    //     imgAlt: res[0].data.caption.rendered.replace(/<[^>]+>/g, ''),
    //     imgLoaded: true,
    //     authorName: res[1].data.name
    //   });
    // });
  }

  watchVideo = (e) => {
    window.open('https://www.youtube.com/watch?v='+e.target.dataset.video, '_blank');
  }

  render() {
    const podcast = this.props.podcast;

    function updatePodcast() {
      // console.dir(podcast);
      let fp = document.querySelector('.featuredPodcast'),
          thumb = fp.querySelector('.thumb'),
          data = fp.querySelector('.data');

      let ndate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit"
      }).format(new Date(podcast.pubDate));

      let ntitle = podcast.title;
      let nmeta = '<span>'+ndate+'</span> <span>'+podcast.episodeType+'</span> <span>'+podcast.duration+'</span>';
      let audioUrl = 'https://admin.toyrewindpodcast.com/storage/uploads' + podcast.audioFile.path;

      if (podcast.webImage)
        thumb.setAttribute('style', 'background-image: url(https://admin.toyrewindpodcast.com/storage/uploads' + podcast.webImage.path + ')');
      data.children[0].innerHTML = ntitle;
      data.children[1].innerHTML = nmeta;
      data.children[2].children[0].src = audioUrl;
      data.children[2].load();
      data.children[2].play();
    }

    return (
      <>
        <div className="col-6">
          <div className="podcast">
            { podcast.webImage && (
              <div className="thumb" style={{ backgroundImage: 'url(https://admin.toyrewindpodcast.com/storage/uploads' + podcast.webImage.path + ')' }}></div>
            )}
            <div className="content">
              <h3>{ podcast.title }</h3>

              <div className="meta">
                <span>{ new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                      }).format(new Date(podcast.pubDate))}</span>
                <span>{ podcast.episodeType }</span>
                <span>{ podcast.duration }</span>
                { podcast.toyCompany && (
                  <span >{ podcast.toyCompany[0] }</span>
                )}
              </div>

              <p>{ podcast.description }</p>
              <p className="keywords">
                {podcast.keywords.map((keyword, index) =>
                    <span key={ `${keyword}` } dangerouslySetInnerHTML={{ __html: `#${ keyword.replace(/ /g,"").toLowerCase() }` }} />
                )}
              </p>

              <div className="buttons">
                <button onClick={ updatePodcast }><i className="fa-solid fa-headphones"></i> Play Episode</button>
                {podcast.videoID && (
                  <button onClick={ this.watchVideo } data-video={ podcast.videoID }><i className="fa-brands fa-youtube"></i> Watch Video</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PodcastBlock;