import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

export class Footer extends Component  {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <p>&copy; { this.getYear() } <strong>ToyRewindPodcast.com</strong> | All Rights Reserved. | <a href="/sitemap.xml" title="sitemap">Sitemap</a></p>
              </div>

              <div className="col-6">
                <ul className="social">
                  <li>
                    <a target="_blank" href="https://www.facebook.com/groups/1043852176030211/" title="Facebook" rel="noopener noreferrer">
                      <span className="hidden">Facebook</span>
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.youtube.com/channel/UCqHEmLFvINp1e1pia-GprYA" title="YouTube" rel="noopener noreferrer">
                      <span className="hidden">YouTube</span>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/toyrewindpod/" title="Twitter" rel="noopener noreferrer">
                      <span className="hidden">Twitter</span>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/toyrewindpod/" title="Instagram" rel="noopener noreferrer">
                      <span className="hidden">Instagram</span>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer