import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import axios from 'axios';

export class FeaturedPodcastBlock extends Component {
  static propTypes = {
    podcast: PropTypes.object.isRequired
  }

  componentDidMount () {
    // console.dir(this.props.podcast);
    // const { featured_media, author } = this.props.post;
    // const getImageUrl = axios.get(`https://app.marca-global.com/wp-json/wp/v2/media/${featured_media}`);

    // Promise.all([getImageUrl, getAuthor]).then(res => {
    // console.dir(res);
    //   this.setState({
    //     imgUrl: res[0].data.media_details.sizes.full.source_url,
    //     imgAlt: res[0].data.caption.rendered.replace(/<[^>]+>/g, ''),
    //     imgLoaded: true,
    //     authorName: res[1].data.name
    //   });
    // });
  }

  render() {
    const featured = this.props.podcast;
    // featured.pubDate = new Date(featured.pubDate);
    // console.dir(featured);

    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">

                <div className="featuredPodcast">
                  { featured.webImage && (
                    <div className="thumb" style={{ backgroundImage: 'url(https://admin.toyrewindpodcast.com/storage/uploads' + featured.webImage.path + ')' }}></div>
                  )}
                  <div className="data">
                    <h2>{ featured.title }</h2>
                    <div className="meta">
                      <span>{ new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit'
                        }).format(new Date(featured.pubDate))}</span>
                      <span>{ featured.episodeType }</span>
                      <span>{ featured.duration }</span>
                    </div>

                    <audio controls>
                      <source src={ "https://admin.toyrewindpodcast.com/storage/uploads/" + featured.audioFile.path } type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default FeaturedPodcastBlock;