import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
// import axios from 'axios';

export class PhotoGallery extends Component {
  state = {
    media: this.props.media,
    isLoaded: false
  }

  componentDidMount () {
    this.setState({ isLoaded: true });
  }

  selectPhoto = (e) => {
    let newPhoto = (e.target.style.backgroundImage.slice(5,-2));
    // let lgPhoto = document.querySelector('.photoGallery');
    let lgPhoto = document.querySelector('.largPhoto img');

    // lgPhoto.style.backgroundImage = 'url("'+newPhoto+'")';
    lgPhoto.setAttribute('src', newPhoto);
  }

  render() {
    const { isLoaded, media } = this.state;
    // console.dir(media);

    return (
      <>
      {isLoaded && (
        <>
          <section className="gallery">
            <h2><i className="fa-solid fa-photo-film" /> Gallery</h2>
            <div className="largPhoto">
              <img src={ 'https://admin.ithinkpixels.com' + media[1].path } alt={ media[1].meta.title } />
            </div>

            <div className="thumbs">
              {media.map((photo, index) =>
                <div className="thumb" key={ index } style={{ backgroundImage: 'url(https://admin.ithinkpixels.com/' + photo.path + ')' }} onClick={ this.selectPhoto } />
              )}
            </div>
          </section>
        </>
      )}
      </>
    )
  }
}

export default PhotoGallery;